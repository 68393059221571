<template>
  <div>
    <div class="banner"></div>
    <el-card class="box-card">
      <div class="header-body">
        <div>
          <div class="zhanshi">
            <pic-zoom :url="imgPath" />
          </div>
          <div class="imgTit">
            <div v-for="(item, index) in productDetailsData.filePaths" :key="index">
              <img :src="item.text" alt="" :class="{ changeStyle: changeSelectStyle == index }"
                @mouseover="changeStyle(index, item)" />
            </div>
          </div>
        </div>
        <div class="right">
          <p class="tit">{{ productDetailsData.name }}</p>
          <div class="price">
            <p class="p2">
              商品品牌：<span class="shop-font"></span><span>{{ productDetailsData.brandName || "" }}</span>
            </p>
            <p class="p1">
              商品价格：<span class="shop-font">￥</span><span style="font-size: 25px;">{{
                productDetailsData.price || "0.00"
              }}</span>
            </p>
            <p class="p1">
              库存数量：<span class="shop-font"></span><span>{{ productDetailsData.storageCount || "0" }}</span>
            </p>
            <p class="p2">
              <span style="letter-spacing: 11.5px;">说明：</span>
              <span style="margin-left: 3px;">{{ productDetailsData.remark }}</span>
            </p>
          </div>
          <div>
            <span style="margin-right: 18px;">购买数量</span>
            <el-input-number size="small" v-model="num" @change="handleChange" :min="1"
              :max="productDetailsData.storageCount || 10" label="描述文字"></el-input-number>
          </div>
          <div style="margin-top: 30px;">
            <el-button @click="buy" class="shop-button-buy">立即购买
            </el-button>
            <el-button @click="goCar" class="shop-button-add">加入购物车</el-button>
          </div>
        </div>
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span style="font-size: 18px;">产品详情</span>
      </div>
        <div class="ql-snow cumWidth">
          <div class="ql-editor" v-html="productDetailsData.productIntroduction"></div>
        </div>
    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { permissionMixin } from "@/mixin";
import PicZoom from "@/components/PicZoom";
export default {
  name: "adressMaintain",
  components: {
    PicZoom,
  },
  mixins: [permissionMixin],
  data() {
    return {
      num: 1,
      imgPath: "",
      changeSelectStyle: "",
    };
  },
  computed: {
    ...mapState({
      productDetailsData: (state) => {
        return state.sparePartsMall.productDetailsData;
      },
    }),
  },
  created() {
    this.fetch();
  },
  methods: {
    changeStyle(index, item) {
      this.changeSelectStyle = index;
      this.imgPath = item.text;
    },
    handleChange(value) {
      console.log(value);
    },
    fetch() {
      this.$store
        .dispatch("sparePartsMall/getProductDetails", { ...this.$route.query })
        .then((res) => {
          if (res) {
            this.imgPath = this.productDetailsData.filePaths[0].text;
          }
        });
    },
    buy() {
      this.queryPermis(() => {
        this.$router.push({
          path: "/fillInTheOrder/fillInTheOrder",
          query: {
            shopList: JSON.stringify([
              { productId: this.$route.query.id, count: this.num },
            ]),
          },
        });
      }, true);
    },
    goCar() {
      this.queryPermis(() => {
        this.$store
          .dispatch("sparePartsMall/addShoppingCart", {
            ...this.$route.query,
            Count: this.num,
          })
          .then((results) => {
						this.$store.dispatch("getShoppingCartCount");
            results &&
              this.$message({
                message: "加入购物车成功",
                type: "success",
              });
          });
      }, true);
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  text-align: center;
  height: 480px; /* no */
  background-image: url(/img/banner.png);
  object-fit: cover;
  background-size: 100%;
}
.box-card {
  width: 1180px; /* no */
  margin: 20px auto; /* no */
}
.header-body {
  display: flex;
}
.shop-font {
  margin-left: 9px; /* no */
}
.shop-button-buy {
  margin: 0 20px 0 0; /* no */
  background-color: #c91c25;
  color: white;
  width: 150px; /* no */
}
.shop-button-add {
  color: #c91c25;
  border: solid 1px #c91c25; /* no */
  width: 150px; /* no */
}
.cumWidth {
  width: 78%;
  margin: 0 auto;
}
.imgTit {
  display: flex;
  width: 500px; /* no */
  overflow-x: scroll;
  img {
    width: 60px; /* no */
    height: 60px; /* no */
    object-fit: cover;
    margin-right: 20px; /* no */
    border: 1px solid transparent; /* no */
  }
  .changeStyle {
    border: solid 1px #24a2aa; /* no */
  }
}

.zhanshi {
  margin-bottom: 20px; /* no */
  width: 500px; /* no */
  height: 500px; /* no */
	position: relative;
  /deep/.magnifier-box {
    img {
      height: 100%;
    }
  }
}

.right {
  width: 100%;
  margin-left: 21px; /* no */

  .tit {
    height: 66px; /* no */
    line-height: 66px; /* no */
    font-size: 24px; /* no */
    font-weight: bold;
    color: #333333;
    border-bottom: solid 1px #eeeeee; /* no */
  }

  .type {
    display: flex;
    justify-content: space-between;
    margin-top: 15px; /* no */

    li {
      font-size: 14px; /* no */
      font-family: SourceHanSansCN;
      font-weight: 400;
      color: #666666;
      line-height: 40px; /* no */
    }
  }

  .price {
    margin: 80px 0 28px 0; /* no */
    border: solid 1px #eeeeee; /* no */
    padding: 19px 0 20px 20px; /* no */
    .p1 {
      color: #666666;
      margin-bottom: 19px; /* no */

      span {
        color: #c91c25;
      }
    }

    .p2 {
      color: #666666;

      span {
        color: #333333;
        // margin-left: 9px;
      }
    }
  }
}

.imgInfo {
  padding: 0 145px 0 145px; /* no */
  text-align: center;
  img {
    width: 910px; /* no */
  }
}
</style>
